import React from 'react';
import { withRouter } from 'react-router';
import { TruncatedText } from '@/se/components/typography';
import { formatPhoneNumber, processPhoneNumbersString } from '@/se/components/inputs/PhoneInput';
import RouterLink from '../../../se/components/RouterLink';
import { sortNumber, sortString } from '@/util/sort';
import { Check } from '@material-ui/icons';
import { PinColumn } from '../common/columns';
import { gql } from '@apollo/client';
import { fragments } from '@/graph/physicians';
import { formatProviderId } from '@/util/providerId';
import TrackingColumn from '../../tracking/TrackingColumn';

export const nameColumn = {
  title: 'Name',
  lens: item => item.name,
  sortFunction: (l, r) => sortString(l.name, r.name),
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const trackingColumn = {
  title: 'Location',
  lens: data => ({ bracelet: data.bracelet, room: data.room }),
  Component: ({ data: { bracelet, room } }) => <TrackingColumn bracelet={bracelet} room={room} />,
};

export const emailColumn = {
  title: 'Email',
  lens: item => item.email,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const hstIdColumn = {
  title: 'Provider Id',
  lens: item => item.hstId,
  sortFunction: (l, r) => sortNumber(l.hstId, r.hstId),
  Component: props => <TruncatedText>{formatProviderId(props.data)}</TruncatedText>,
};

export const smsNotificationColumn = {
  title: 'SMS Notification Numbers',
  lens: data =>
    data.notificationNumbers !== null && data.notificationNumbers !== undefined
      ? formatPhoneNumber(processPhoneNumbersString(data.notificationNumbers).nationalNumbers)
      : undefined,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const scheduleAccessColumn = {
  title: 'Schedule Access',
  lens: data => data,
  Component: withRouter(props => (
    <RouterLink onClick={e => e.stopPropagation()} to={`${props.match.url}/schedule-access/${props.data.id}`}>
      {props.data.hasScheduleAccess ? <Check fontSize={'small'} style={{ marginRight: '.25em' }} /> : null}
      <TruncatedText>{props.data.hasScheduleAccess ? 'Yes' : 'Manage'}</TruncatedText>
    </RouterLink>
  )),
};

export const sendNotificationColumn = {
  title: 'Notify',
  lens: item => item.shouldSendNotification,
  Component: props => <TruncatedText>{props.data ? 'Yes' : 'No'}</TruncatedText>,
};

export const specialityColumn = {
  title: 'Speciality',
  lens: item => item.speciality,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const categoryColumn = {
  title: 'Category',
  lens: item => item.category,
  Component: props => <TruncatedText>{props.data}</TruncatedText>,
};

export const pinColumn = PinColumn({
  getId: data => data.id,
  getName: data => data.name,
  getPin: data => data.pin,
  createPinMutation: gql`
    mutation physicianCreatePin($id: Long!, $pin: String!) {
      physicianSetPin(id: $id, pin: $pin) {
        ...PhysicianData
        pin
      }
    }
    ${fragments.all}
  `,
  issuePinMutation: gql`
    mutation physicianIssuePin($id: Long!) {
      physicianIssuePin(id: $id) {
        ...PhysicianData
        pin
      }
    }
    ${fragments.all}
  `,
  removePinMutation: gql`
    mutation physicianRemovePin($id: Long!) {
      physicianRemovePin(id: $id) {
        ...PhysicianData
        pin
      }
    }
    ${fragments.all}
  `,
});

export const baseColumns = [
  nameColumn,
  hstIdColumn,
  smsNotificationColumn,
  scheduleAccessColumn,
  specialityColumn,
  sendNotificationColumn,
  categoryColumn,
  pinColumn,
  trackingColumn,
];

export const listColumns = [...baseColumns];

export const viewColumns = [
  specialityColumn,
  hstIdColumn,
  sendNotificationColumn,
  smsNotificationColumn,
  emailColumn,
  categoryColumn,
  trackingColumn,
];
