import React from 'react';
import { compose, withProps } from 'recompose';
import queryString from 'query-string';
import TextInput from '../../se/components/inputs/TextInput';
import Label from '../../se/components/Label';
import { getNestedValue } from '../../se/utilities/data/object';
import get from 'lodash/get';
import { withScope } from '../../contexts/ScopeContext';

const ReadOnlyBracelet = ({ braceletId }) => (
  <div style={{ marginBottom: 42 }}>
    <p>
      <strong style={{ fontWeight: 500 }}>Bracelet Id:</strong> <span style={{ opacity: 0.75 }}>{braceletId}</span>
    </p>
    <p style={{ opacity: 0.6, marginTop: 12 }}>
      Please keep the bracelet on top of the gateway until you complete the form.
    </p>
  </div>
);

const InputWithLabel = props => (
  <Label text="Bracelet Id">
    <TextInput
      {...props}
      onChange={props.onChange}
      value={getNestedValue(props.nonPatient ? 'value' : 'value.braceletId', props)}
      disabled={props.disabled}
      autoComplete="off"
    />
  </Label>
);

class Input extends React.Component {
  state = { lastKnowBraceletId: undefined, autoBracelet: false };

  componentDidMount = () => {
    this.processNewBraceletId();
  };

  componentDidUpdate = () => {
    this.processNewBraceletId();
  };

  processNewBraceletId = () => {
    if (this.props.autoDetectBraceletId) {
      const { braceletId } = queryString.parse(window.location.search) || {};
      if (braceletId && !this.props.value && this.state.lastKnowBraceletId !== braceletId) {
        this.setState({ lastKnowBraceletId: braceletId, autoBracelet: true });
        if (this.props.nonPatient) {
          this.props.onChange(braceletId);
        } else {
          this.props.onChange({ braceletId, autoBracelet: true });
        }
      }
    }
  };

  onChange = data => {
    if (this.props.nonPatient) {
      this.props.onChange(data);
    } else {
      this.props.onChange({ braceletId: data, autoBracelet: false });
    }
  };

  render() {
    if (this.props.nonPatient) {
      return <InputWithLabel {...this.props} onChange={this.onChange} disabled={this.props.disabled} />;
    }

    const showInput = get(this.props, 'scope.hospital.modules.automaticPatientTracking', true);

    if (!showInput) {
      return null;
    }

    if (this.state.autoBracelet) {
      return <ReadOnlyBracelet braceletId={getNestedValue('value.braceletId', this.props)} />;
    }
    return <InputWithLabel {...this.props} onChange={this.onChange} disabled={this.props.disabled} />;
  }
}

export default compose(withScope, withProps({ placeholder: 'e.g., AC233FA10D56' }))(Input);
