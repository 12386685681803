import React from 'react';
import Typography from '@material-ui/core/Typography';
import Tooltip from '../../components/Tooltip';

export interface TrackingColumnProps {
  bracelet?: {
    id: string;
    batteryLevel: number;
    updatedAt: string;
  };
  room?: {
    id: number;
    name: string;
  };
}

const TrackingColumn = ({ bracelet, room }: TrackingColumnProps) =>
  bracelet ? (
    <Tooltip content={`Bracelet ${bracelet.id}`}>
      <Typography color={bracelet ? undefined : 'textSecondary'}>{room ? room.name : 'Unknown Room'}</Typography>
    </Tooltip>
  ) : (
    <Typography color={bracelet ? undefined : 'textSecondary'}>No bracelet</Typography>
  );

export default TrackingColumn;
