import React, { FC } from 'react';
import { format } from 'date-fns';
import TableWithColumnSelector, { TableType } from '../../core/TableWithColumnSelector';
import { useQuery } from '@apollo/client';
import { physicianPatientsAnalyticsQuery } from '../../../graph/dashboard';
import { getMinutes } from './util';
import { PhysicianPatientJourneyTableData } from '../../../types/Analytics';
import { DateRangeType } from '../../../types/utils';
import { useScope } from '../../../hooks/useScope';

const toTableData = (data: PhysicianPatientJourneyTableData[], hasPostOp?: boolean): TableType => {
  const postOpInOutHeaders = hasPostOp ? ['POSTOP In', 'POSTOP Out'] : [];
  const postOpHeaders = hasPostOp ? ['POSTOP'] : [];
  const baseHeaders = [
    'Date of service',
    'Patient MRN',
    'Patient',
    'OR',
    'Procedure Type',
    'Wheels In To Start',
    'Ready In PreOp To Wheels In',
    'Wheels In To Ready For Surgery',
    'Ready For Surgery To Start',
    'WR',
    'Pre Op',
    'Wheels-In, Wheels-Out',
    'Start To Operation End',
    'Start To Close',
    'Recovery',
    ...postOpHeaders,
    'Pre OP to Exit',
    'Scheduled OR Time',
    'Schedule Accuracy',
    'Admitted',
    'Pre Op In',
    'Pre Op Out',
    'OR In',
    'Procedure Start',
    'OR Out',
    'Recovery In',
    'Recovery Out',
    ...postOpInOutHeaders,
    'Discharged At',
  ];
  return {
    configHeader: [...baseHeaders],
    headers: [
      {
        id: 'h1',
        columns: [...baseHeaders],
      },
    ],
    rows: (data || []).map(e => ({
      id: e?.id.toString(),
      columns: [
        e.surgeryTime,
        e.patientProviderId || '',
        e.patient,
        e.operationRoom,
        e.procedureType,
        getMinutes(e.piToStartInMinutes),
        getMinutes(e.patientReadyToOrInInMinutes),
        getMinutes(e.inORToReadyForSurgeryInMinutes),
        getMinutes(e.readyForSurgeryToStartInMinutes),
        getMinutes(e.wrInMinutes),
        getMinutes(e.preOpInMinutes),
        getMinutes(e.pipoInMinutes),
        getMinutes(e.procedureDurationInMinutes),
        getMinutes(e.startToCloseInMinutes),
        getMinutes(e.recoveryInMinutes),
        ...(hasPostOp ? [getMinutes(e.postOpInMinutes)] : []),
        getMinutes(e.preOpToExitInMinutes),
        getMinutes(e?.scheduledProcedureDurationInMinutes || 0),
        isFinite(e?.scheduleEfficiency) ? Math.round(e?.scheduleEfficiency * 100) + '%' : '-',
        e.admittedAt,
        e.preOpIn,
        e.preOpOut,
        e.orIn,
        e.cut,
        e.orOut,
        e.recoveryIn,
        e.recoveryOut,
        ...(hasPostOp ? [e.postOpIn] : []),
        ...(hasPostOp ? [e.postOpOut] : []),
        e.dischargedAt,
      ],
    })),
  };
};

const PhysicianPatientsAnalytics: FC<{
  physicianId: number;
  physicianName?: string;
  dateRange: DateRangeType;
  filter: any;
}> = ({ physicianId, physicianName, dateRange, filter }) => {
  const { data } = useQuery(physicianPatientsAnalyticsQuery, {
    variables: {
      physicianId,
      filter,
    },
  });

  const scope = useScope();
  const hasPostOp = scope?.hospital?.modules?.hasPostop;
  const table: PhysicianPatientJourneyTableData[] = data?.physicianPatientsAnalytics || [];

  return (
    <>
      <TableWithColumnSelector
        configName="physician-patients-table-config"
        tableId="Physician-Patients-table"
        tableName={`For physician ${physicianName || ''}`}
        excelFileName={`Patient-Journey-${format(dateRange.from, 'MM/DD/YYYY')}-${format(dateRange.to, 'MM/DD/YYYY')}`}
        {...toTableData(table, hasPostOp)}
      />
    </>
  );
};

export default PhysicianPatientsAnalytics;
