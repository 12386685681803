import React from 'react';
import { useQuery } from '@apollo/client';
import get from 'lodash/fp/get';
import GraphQLCRUD from '../../../se/components/GraphQLCRUD';

import scheme, {
  grantScheduleAccess,
  resendScheduleAccessLink,
  revokeScheduleAccess,
  setScheduleAccessAllAnesthesiologist,
} from '../../../graph/anesthesiologists';
import { baseColumns, viewColumns } from './columns';
import AnesthesiologistInput from '../../inputs/anesthesiologist/AnesthesiologistInput';
import ScheduleAccessEditor from '../../pages/ScheduleAccessEditor';
import ScheduleAccessToggle from '../../pages/ScheduleAccessToggle';
import { set } from 'lodash/fp';

export const AnesthesiologistScheduleAccessEditor = ({
  anesthesiologist,
  onClose,
  refetchQueries = [],
  ...rest
}: any) => (
  <ScheduleAccessEditor
    grantScheduleAccess={grantScheduleAccess}
    resendScheduleAccessLink={resendScheduleAccessLink}
    revokeScheduleAccess={revokeScheduleAccess}
    {...anesthesiologist}
    onClose={onClose}
    {...rest}
  >
    <ScheduleAccessToggle
      scheduleUserId={anesthesiologist?.id}
      scheduleAccessAll={anesthesiologist?.scheduleAccessAll}
      setScheduleAccessAll={setScheduleAccessAllAnesthesiologist}
      refetchQueries={refetchQueries || []}
    />
  </ScheduleAccessEditor>
);

export default GraphQLCRUD({
  entityName: 'Anesthesiologist',
  List: {
    tableKey: 'Anesthesiologist',
    columns: baseColumns,
    simpleFilter: true,
    additionalRoutes: [
      {
        key: 'schedule-access',
        path: '/schedule-access/:id',
        Component: (props: any) => {
          const { data: item, loading } = useQuery(scheme.item, {
            variables: {
              id: parseInt(get('params.id')(props.match), 10),
            },
          });

          return !loading && item.anesthesiologist ? (
            <AnesthesiologistScheduleAccessEditor {...item} {...props} />
          ) : null;
        },
      },
    ],
  },
  Show: {
    columns: viewColumns,
  },
  Create: {
    Input: AnesthesiologistInput,
  },
  Edit: {
    Input: AnesthesiologistInput,
    mapEditItemProps: props =>
      set('data.anesthesiologist.braceletId', get('data.anesthesiologist.bracelet.id')(props))(props),
  },
  scheme,
});
