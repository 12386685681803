import React, { useEffect, useMemo, useState } from 'react';
import { Box, createStyles, Icon, makeStyles, MenuItem, MenuList, Popover, TextField, Chip } from '@material-ui/core';
import { RemoveCircleOutline } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Spinner from '../../../../../se/components/Spinner';
import Anesthesiologist from '../../../../../types/Anesthesiologist';
import { useMutation, useQuery } from '@apollo/client';
import { list as listAnesthesiologists } from '../../../../../graph/anesthesiologists';
import { editProcedureAnesthesiologist } from '../../../../../graph/procedures';
import {
  addAnesthesiologistShiftRoom,
  removeAnesthesiologistShiftRoom,
  addSecondaryAnesthesiologistShiftRoom,
  removeSecondaryAnesthesiologistShiftRoom,
} from '../../../../../graph/rooms';
import anesthesiaSvgIcon from '../../../../../assets/images/anesthesia.svg';
import { format } from 'date-fns';
import { Procedure } from '../../../../../types/Procedure';

const AnesthesiologistNameRoom = ({
  date,
  roomId,
  editableAnesthesiologist,
  anesthesiologistName,
  secondaryAnesthesiologistName,
  condensView,
  anesthesiologistShiftRoomId,
  secondaryAnesthesiologistShiftRoomId,
  procedureQuery,
  roomType,
  secondaryRoomAnesthesiologist,
  autoDischarge,
}: {
  date?: Date;
  roomId: number;
  editableAnesthesiologist?: boolean;
  anesthesiologistName?: string;
  secondaryAnesthesiologistName?: string;
  condensView?: boolean;
  anesthesiologistShiftRoomId?: number;
  secondaryAnesthesiologistShiftRoomId?: number;
  procedureQuery?: Array<Procedure>;
  roomType?: string;
  secondaryRoomAnesthesiologist?: boolean;
  autoDischarge?: boolean;
}) => {
  const classes = useStyles();

  const searchEl = React.useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const [secondaryAnchorEl, setSecondaryAnchorEl] = useState<HTMLElement | null>(null);
  const secondaryOpen = Boolean(secondaryAnchorEl);
  const secondaryPopoverId = secondaryOpen ? 'secondary-popover' : undefined;

  useEffect(() => {
    if (open) {
      searchEl.current?.focus();
    } else {
      setSearch('');
    }
  }, [open]);

  const [search, setSearch] = useState('');

  const handleSearchUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.currentTarget.value);
  };

  const openEditor = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    editableAnesthesiologist && setAnchorEl(e.currentTarget);
  };

  const openSecondaryEditor = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    editableAnesthesiologist && setSecondaryAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSecondaryClose = () => {
    setSecondaryAnchorEl(null);
  };

  const { data } = useQuery(listAnesthesiologists);
  const anesthesiologists: Anesthesiologist[] = useMemo(() => data?.anesthesiologists || [], [data?.anesthesiologists]);

  const filteredAnesthesiologists = useMemo(() => {
    const chunks = search.toLowerCase().split(/\s+/);
    return (anesthesiologists || []).filter((a: Anesthesiologist) => {
      const name = a.name.toLowerCase();
      return chunks.every(chunk => name.indexOf(chunk) >= 0);
    });
  }, [search, anesthesiologists]);

  const [setRoomAnesthesiologist] = useMutation(addAnesthesiologistShiftRoom);
  const [removeRoomAnesthesiologist] = useMutation(removeAnesthesiologistShiftRoom);
  const [setRoomSecondaryAnesthesiologist] = useMutation(addSecondaryAnesthesiologistShiftRoom);
  const [removeRoomSecondaryAnesthesiologist] = useMutation(removeSecondaryAnesthesiologistShiftRoom);

  const selectAnesthesiologist = async (anesthesiologistId: number) => {
    try {
      date &&
        (await setRoomAnesthesiologist({
          variables: {
            id: roomId,
            date: format(date, 'YYYY-MM-DD'),
            anesthesiologistId: anesthesiologistId,
            roomTypeString: roomType,
          },
        }));
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const handleRemoveAnesthesiologist = async (id: number) => {
    try {
      date &&
        (await removeRoomAnesthesiologist({
          variables: { id: anesthesiologistShiftRoomId, roomTypeString: roomType },
        }));
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const selectSecondaryAnesthesiologist = async (anesthesiologistId: number) => {
    try {
      date &&
        (await setRoomSecondaryAnesthesiologist({
          variables: {
            id: roomId,
            date: format(date, 'YYYY-MM-DD'),
            anesthesiologistId: anesthesiologistId,
            roomTypeString: roomType,
          },
        }));
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  const handleRemoveSecondaryAnesthesiologist = async (id: number) => {
    try {
      date &&
        (await removeRoomSecondaryAnesthesiologist({
          variables: { id: secondaryAnesthesiologistShiftRoomId },
        }));
    } catch (e) {
      console.log(e);
    } finally {
    }
  };

  return (
    <>
      <Box className={classes.editLabel} onClick={openEditor}>
        {anesthesiologistName ? (
          (anesthesiologistName || '-').substring(0, condensView ? 15 : undefined)
        ) : editableAnesthesiologist ? (
          <Icon>
            <img src={anesthesiaSvgIcon} alt="add-anesthesiologist" width={22} height={22} />
          </Icon>
        ) : (
          '-'
        )}
      </Box>
      {open && (
        <Popover
          id={popoverId}
          open={open}
          anchorEl={anchorEl}
          onClick={e => e.stopPropagation()}
          onClose={handleClose}
          getContentAnchorEl={null}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {anesthesiologists ? (
            <Box className={classes.root}>
              <TextField
                size="small"
                variant="outlined"
                value={search}
                onChange={handleSearchUpdate}
                inputRef={searchEl}
                InputProps={{
                  startAdornment: <SearchIcon fontSize="small" className={classes.searchIcon} />,
                  autoCapitalize: 'off',
                  autoComplete: 'off',
                  autoCorrect: 'off',
                  classes: {
                    root: classes.inputRoot,
                    inputAdornedStart: classes.inputAdornedStart,
                  },
                }}
                classes={{
                  root: classes.textFieldRoot,
                }}
              />
              <MenuList onClick={e => e.stopPropagation()} className={classes.menu}>
                {filteredAnesthesiologists.map((a: Anesthesiologist) => (
                  <MenuItem key={a.id} onClick={() => selectAnesthesiologist(a.id)}>
                    {a.name}
                  </MenuItem>
                ))}
                <MenuItem onClick={handleRemoveAnesthesiologist}>
                  <Chip
                    icon={<RemoveCircleOutline className={classes.iconRemove} />}
                    label={'Remove Slot'}
                    size="small"
                    className={classes.chip}
                  />
                </MenuItem>
              </MenuList>
            </Box>
          ) : (
            <div className={classes.spinnerContainer}>
              <Spinner />
            </div>
          )}
        </Popover>
      )}

      {/* Secondary Anesthesiologist */}
      {secondaryRoomAnesthesiologist && (
        <Box
          className={`${classes.editLabel} ${secondaryAnesthesiologistName ? classes.secondaryAnesthesiologist : ''}`}
          onClick={openSecondaryEditor}
        >
          {secondaryAnesthesiologistName ? (
            (secondaryAnesthesiologistName || '-').substring(0, condensView ? 15 : undefined)
          ) : editableAnesthesiologist ? (
            <Icon>
              <SupervisorAccountIcon style={{ color: '#00A7F7' }} />
            </Icon>
          ) : (
            '-'
          )}
        </Box>
      )}
      {secondaryOpen && (
        <Popover
          id={secondaryPopoverId}
          open={secondaryOpen}
          anchorEl={secondaryAnchorEl}
          onClick={e => e.stopPropagation()}
          onClose={handleSecondaryClose}
          getContentAnchorEl={null}
          keepMounted
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {anesthesiologists ? (
            <Box className={classes.root}>
              <TextField
                size="small"
                variant="outlined"
                value={search}
                onChange={handleSearchUpdate}
                inputRef={searchEl}
                InputProps={{
                  startAdornment: <SearchIcon fontSize="small" className={classes.searchIcon} />,
                  autoCapitalize: 'off',
                  autoComplete: 'off',
                  autoCorrect: 'off',
                  classes: {
                    root: classes.inputRoot,
                    inputAdornedStart: classes.inputAdornedStart,
                  },
                }}
                classes={{
                  root: classes.textFieldRoot,
                }}
              />
              <MenuList onClick={e => e.stopPropagation()} className={classes.menu}>
                {filteredAnesthesiologists.map((a: Anesthesiologist) => (
                  <MenuItem key={a.id} onClick={() => selectSecondaryAnesthesiologist(a.id)}>
                    {a.name}
                  </MenuItem>
                ))}
                <MenuItem onClick={handleRemoveSecondaryAnesthesiologist}>
                  <Chip
                    icon={<RemoveCircleOutline className={classes.iconRemove} />}
                    label={'Remove Slot'}
                    size="small"
                    className={classes.chip}
                  />
                </MenuItem>
              </MenuList>
            </Box>
          ) : (
            <div className={classes.spinnerContainer}>
              <Spinner />
            </div>
          )}
        </Popover>
      )}
    </>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      maxHeight: '38rem',
      overflow: 'hidden',
    },
    menu: {
      maxHeight: '28rem',
      overflowY: 'scroll',
    },
    textFieldRoot: {
      margin: '1rem',
      marginBottom: 0,
      padding: 0,
    },
    inputRoot: {
      width: '14rem',
      fontSize: '0.8125em',
      padding: '0.25em',
    },
    searchIcon: {
      marginLeft: '0.25em',
      marginRight: '0.25em',
    },
    inputAdornedStart: {
      paddingLeft: 0,
    },
    spinnerContainer: {
      padding: '1rem',
    },
    editLabel: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    chip: {
      backgroundColor: 'transparent',
      cursor: 'pointer',
      color: 'white',
    },
    iconRemove: {
      color: 'red',
    },
    secondaryAnesthesiologist: {
      color: '#00A7F7',
    },
  })
);

export default AnesthesiologistNameRoom;
