import React from 'react';
import styled from 'styled-components';
import { compose, mapProps, withProps } from 'recompose';
import ObjectInput from '../../../se/components/inputs/ObjectInput';

import TextInput from '../../../se/components/inputs/TextInput';
import AddressInput from '../AddressInput';
import PhoneInput from '../../../se/components/inputs/PhoneInput';
import MonitoringAssignment from '../../entities/hospitals/MonitoringAssignment';
import GroupAdminAssignment from '../../entities/hospitals/GroupAdminAssignment';
import { withLabel } from '../../../se/components/Label';
import NotesInput from '../NotesInput';
import NumberInput from '../../../se/components/inputs/NumberInput';
import ToggleInput from '../../../se/components/inputs/ToogleInput';
import graphqlSelectInput from '../graphqlSelectInput';
import { externalScheduleProviders, timezoneOptions } from '../../../graph/hospitals';
import get from 'lodash/get';
import SchedulePdfsMultipleSelectInput from '../SchedulePdfsMultipleSelectInput';
import { withDescription } from '../../../se/components/Description';
import AnalyticsPdfsMultipleSelectInput from '../AnalyticsPdfsMultipleSelectInput';

const PhoneNumberInput = compose(
  withLabel('Contact Phone Number'),
  withProps({ placeholder: 'e.g., (619) 412-9512' })
)(PhoneInput);

const Span = styled.span`
  display: flex;
  width: 100%;
  align-items: center;
  > :first-child {
    flex: 1;
  }
  * + * {
    margin: 0;
    padding-left: 1rem;
  }
`;

export const LinkInput = withProps({
  schema: {
    link: withProps({ placeholder: 'e.g., https://g.page/ucsd/review?mt', type: 'url' })(TextInput),
    enabled: ToggleInput,
  },
  render: ({ link, enabled }) => (
    <Span style={{ display: 'flex' }}>
      {link}
      {enabled}
    </Span>
  ),
})(ObjectInput);

const ScheduleProviderInput = withProps({
  isClearable: true,
})(
  graphqlSelectInput({
    entityName: 'externalScheduleProvider',
    label: 'External Schedule Provider',
    placeholder: 'Select',
    graphqlList: externalScheduleProviders,
    transformOptions: options => options.map(opt => ({ id: opt, name: opt })),
  })
);

export const TimezoneInput = graphqlSelectInput({
  entityName: 'timezone',
  label: 'Timezone',
  placeholder: 'Select',
  graphqlList: timezoneOptions,
  getOptionValue: option => (option ? option.value : undefined),
  transformOptions: options => options.map(({ id, name }) => ({ id, name })),
});

export default withProps({
  schema: {
    name: withProps({ placeholder: 'e.g. Surgical Care Partners', required: true })(withLabel('Name')(TextInput)),
    address: AddressInput,
    contact: PhoneNumberInput,
    notes: NotesInput,
    reviewIntegrations: compose(
      withLabel('Patient Review Integrations', { section: true }),
      withProps({
        schema: {
          google: withLabel('Google')(LinkInput),
          yelp: withLabel('Yelp!')(LinkInput),
        },
      })
    )(ObjectInput),
    caretakerReviewIntegrations: compose(
      withLabel('Caretaker Review Integrations', { section: true }),
      withProps({
        schema: {
          google: withLabel('Google')(LinkInput),
          yelp: withLabel('Yelp!')(LinkInput),
        },
      })
    )(ObjectInput),
    ospitekOrdersIntegrations: compose(
      withLabel('Ospitek Orders Integrations', { section: true }),
      withProps({
        schema: {
          externalLink: withLabel('External Link')(LinkInput),
        },
      })
    )(ObjectInput),
    monitors: withLabel('Monitoring Engineers')(MonitoringAssignment),
    groupAdmins: withLabel('Group Admins')(GroupAdminAssignment),
    minBracelets: withProps({ min: 1 })(withLabel('Minimum Active Bracelets')(NumberInput)),
    questionnaireValidMonths: withProps({ min: 0, required: true })(
      withLabel('Questionnaire valid in months for repeated patients')(NumberInput)
    ),
    passwordRefreshDays: withProps({ min: 0 })(
      withLabel('Period in days after which user is forced to change password')(NumberInput)
    ),
    sessionTimeout: withProps({ min: 60 })(withLabel('Session Expiration Timeout (in seconds)')(NumberInput)),
    scheduleProvider: ScheduleProviderInput,
    timezone: mapProps(props => ({ ...props, value: get(props, 'value.id', get(props, 'value')) }))(TimezoneInput),
    modules: compose(
      withLabel('Hospital Module Use', { section: true }),
      withProps({
        schema: {
          temperatureReporting: withProps({ label: 'Temperature Sensors and Reporting' })(ToggleInput),
          patientRegistration: withProps({ label: 'Patient Enrollment' })(ToggleInput),
          blockSchedule: withProps({ label: 'Block Schedule' })(ToggleInput),
          airFiltrationTimer: withProps({ label: 'Air Filtration Warning' })(ToggleInput),
          automaticPatientTracking: withProps({ label: 'Automatic Patient Tracking' })(ToggleInput),
          lightMode: withProps({ label: 'Light Mode' })(ToggleInput),
          acceptsProcedureTransfer: withProps({ label: 'Accepts Procedure Transfers' })(ToggleInput),
          hasPostop: withProps({ label: 'Has Post OP' })(ToggleInput),
          hasPatientTypes: withProps({ label: 'Has Patient Types' })(ToggleInput),
          createProcedureSchedule: withProps({ label: 'Create Procedure On Schedule' })(ToggleInput),
          noteTablet: withProps({ label: 'Pre-Op and Pacu Note on Tablet' })(ToggleInput),
          patientCharting: withProps({ label: 'Patient Chart' })(ToggleInput),
          preOpStatusReset: withProps({ label: 'Pre-Op Status Reset' })(ToggleInput),
          orTabletV2: withProps({ label: 'Operation Room Tablet v2' })(ToggleInput),
          inTakeForms: withProps({ label: 'Enable Registration Package' })(ToggleInput),
          dictation: withProps({ label: 'Enable Dictation' })(ToggleInput),
          showQRCode: withProps({ label: 'Show QR Code on Forms' })(ToggleInput),
          analyticsV2: withProps({ label: 'Analytics V2' })(ToggleInput),
          analyticsV2PieCharts: withProps({ label: 'Analytics V2 Pie Charts' })(ToggleInput),
          showStaffCost: withProps({ label: 'Staff Cost' })(ToggleInput),
          showPreOpNote: withProps({ label: 'Show PreOp Note on Forms' })(ToggleInput),
          showCSVUpload: withProps({ label: 'Upload Schdule CSV' })(ToggleInput),
          showWeekends: withProps({ label: 'Show Weekends on Weekly/Monthly Schedule' })(ToggleInput),
          secondaryRoomAnesthesiologist: withProps({ label: 'Enable Secondary Room Anesthesiologist' })(ToggleInput),
          autoDischarge: withProps({ label: 'Auto Discharge Before 24h In' })(ToggleInput),
          preOpPriority: compose(
            withLabel('PreOp Priority', { section: true }),
            withProps({
              schema: {
                hasPreOpPriority: withProps({ label: 'Has PreOp Priority' })(ToggleInput),
                priorityCounter: withProps({ min: 0 })(withLabel('Priority Counter')(NumberInput)),
              },
              initialValue: {
                hasPreOpPriority: false,
                priorityCounter: 0,
              },
            })
          )(ObjectInput),
          schedulePDFs: withLabel('Select Schedule PDFs')(SchedulePdfsMultipleSelectInput),
          analyticsPDFs: withLabel('Select Analytics PDFs')(AnalyticsPdfsMultipleSelectInput),
          chartingIPAddressWhitelistEnabled: withProps({ label: 'Charting IP Address Whitelist' })(ToggleInput),
          chartingIPAddressWhitelist: compose(
            withLabel('Charting IP Addresses Whitelist'),
            withDescription('To add multiple IP addresses, separate them with a comma.')
          )(TextInput),
          ipAddressWhitelistEnabled: withProps({ label: 'IP Address Whitelist' })(ToggleInput),
          ipAddressWhitelist: compose(
            withLabel('IP Addresses Whitelist'),
            withDescription('To add multiple IP addresses, separate them with a comma.')
          )(TextInput),
        },
        initialValue: {
          preOpPriority: {
            hasPreOpPriority: false,
            priorityCounter: 0,
          },
        },
      })
    )(ObjectInput),
  },
})(ObjectInput);
